<template>
    <div class="pointRule_detail">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="point" :model="pointRule" label-width="120px" :rules="rules" size="small">
                <el-form-item label="企业名称">
                    {{ form.companyName }}
                </el-form-item>
                <el-form-item label="机构组名称">
                    {{ form.deptGroupName }}
                </el-form-item>
                <el-form-item label="消费金额(元)" prop="money">
                    <el-input v-model.number="money" />
                </el-form-item>
                <el-form-item label="积分" prop="point">
                    <el-input v-model.number="pointRule.point" />
                </el-form-item>
                <el-form-item label="清零日期(月-日)">
                    <el-date-picker v-model="clearTime" format="MM-dd" clearable="false" />
                </el-form-item>
                <el-form-item label="有效期(年)">
                    <el-select v-model.number="pointRule.effectiveTime">
                        <el-option label="一年" :value="1" />
                        <el-option label="两年" :value="2" />
                        <el-option label="三年" :value="3" />
                        <el-option label="五年" :value="5" />
                        <el-option label="十年" :value="10" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="save">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import MoneyUtils from '../../../../js/MoneyUtils';

export default {
    name: 'PointRuleCreate',
    props: ['form'],
    data() {
        return {
            pointRule: {
                code: '',
                money: '',
                point: null,
                effectiveTime: '',
                ruleEndMonth: null,
                ruleEndDay: null,
            },
            clearTime: {},
            money: 0,
            url: {
                save: '/member/pointRule/patch_c/',
            },
            rules: {
                money: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入正确的金额',
                        type: 'integer',
                        min: 1,
                        max: 99999999,
                    },
                ],
                point: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入正确的积分',
                        type: 'integer',
                        min: 1,
                        max: 99999999,
                    },
                ],
            },
        };
    },
    watch: {
        clearTime(value) {
            this.pointRule.ruleEndMonth = value.getMonth() + 1;
            this.pointRule.ruleEndDay = value.getDate();
        },
        money(value) {
            this.pointRule.money = MoneyUtils.moneyToDb(value);
        },
    },
    mounted() {
        this.pointRule.ruleEndMonth = this.form.ruleEndMonth;
        this.pointRule.ruleEndDay = this.form.ruleEndDay;
        this.pointRule.code = this.form.code;
        this.pointRule.money = this.form.money;
        this.pointRule.point = this.form.point;
        this.pointRule.effectiveTime = this.form.effectiveTime;

        this.clearTime = new Date();
        this.clearTime.setFullYear(new Date().getFullYear(), this.form.ruleEndMonth - 1, this.form.ruleEndDay);

        this.money = MoneyUtils.moneyToYuan(this.pointRule.money);
    },
    methods: {
        save() {
            this.$refs.point.validate((valid) => {
                if (!valid) return;
                UrlUtils.PatchRemote(this, this.url.save + this.pointRule.code, this.pointRule, null, () => {
                    this.$message.success('保存成功');
                    this.goBackAndReload();
                });
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
